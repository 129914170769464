<template>
	<svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_1471_2495)">
			<path d="M21.62 0.479126H1.38C0.619158 0.479126 0 1.10921 0 1.88347V14.9907C0 15.7649 0.619158 16.395 1.38 16.395H21.62C22.3808 16.395 23 15.7649 23 14.9907V1.88347C23 1.10921 22.3808 0.479126 21.62 0.479126ZM22.08 14.9907C22.08 15.2481 21.873 15.4588 21.62 15.4588H1.38C1.1261 15.4588 0.92 15.2481 0.92 14.9907V1.88347C0.92 1.62505 1.1261 1.41535 1.38 1.41535H21.62C21.873 1.41535 22.08 1.62505 22.08 1.88347V14.9907Z" fill="#FF5733" />
			<path d="M22.54 4.69214H0.459961V6.5646H22.54V4.69214Z" fill="#FF5733" />
			<path d="M22.54 4.224H0.46C0.206102 4.224 0 4.43369 0 4.69211V6.56457C0 6.82299 0.206102 7.03268 0.46 7.03268H22.54C22.7939 7.03268 23 6.82299 23 6.56457V4.69211C23 4.43369 22.7939 4.224 22.54 4.224ZM22.08 6.09646H0.92V5.16023H22.08V6.09646Z" fill="#FF5733" />
			<path d="M12.4199 9.84131H4.13993C3.88603 9.84131 3.67993 10.051 3.67993 10.3094C3.67993 10.5678 3.88603 10.7775 4.13993 10.7775H12.4199C12.6738 10.7775 12.8799 10.5678 12.8799 10.3094C12.8799 10.051 12.6738 9.84131 12.4199 9.84131Z" fill="#FF5733" />
			<path d="M8.73993 11.7139H4.13993C3.88603 11.7139 3.67993 11.9236 3.67993 12.182C3.67993 12.4404 3.88603 12.6501 4.13993 12.6501H8.73993C8.99383 12.6501 9.19993 12.4404 9.19993 12.182C9.19993 11.9236 8.99383 11.7139 8.73993 11.7139Z" fill="#FF5733" />
		</g>
		<defs>
			<clipPath id="clip0_1471_2495">
				<rect width="23" height="16" fill="white" transform="translate(0 0.437012)" />
			</clipPath>
		</defs>
	</svg>
</template>
