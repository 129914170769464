const CREDIT_CARD = 'creditCard'
const BANK_ACCOUNT = 'bankAccount'
const CHECK_OR_OFFLINE_PAYMENT = 'checkOrOfflinePayment'

const SERVICE = 'service'
const INSTALL = 'install'
const EQUIPMENT = 'equipment'
const STATIC_IPS = 'static_ips'
const VOIP = 'voip'
const CREDIT = 'credit'

const INVOICE_ITEM_TYPES = {
	SERVICE,
	INSTALL,
	EQUIPMENT,
	STATIC_IPS,
	VOIP,
	CREDIT
}

const TAX_RATE = 0.02

export { CREDIT_CARD, BANK_ACCOUNT, CHECK_OR_OFFLINE_PAYMENT, TAX_RATE, INVOICE_ITEM_TYPES, INSTALL }
