import type { PaymentType, InvoiceApiResponse, MappedInvoice, InvoiceItem, MappedInvoiceItem, InvoicePayment, MappedInvoicePayment, InvoiceCustomerInfo, MappedInvoiceCustomerInfo, PriceSummary } from '@/components/account/invoices/types'
import type { PaymentMethod, PaymentMethodsApiResponse } from '@/components/account/paymentMethods/types'
import { formatDate } from '@/utils/formatDate'
import { CREDIT_CARD, BANK_ACCOUNT, CHECK_OR_OFFLINE_PAYMENT } from '@/constants/payment'

const mapInvoiceItems = (invoiceItems: InvoiceItem[]): MappedInvoiceItem[] => {
	return invoiceItems?.map(({
		itemId: id,
		item_name: itemName,
		item_description: itemDescription = '',
		type,
		charge,
		adjusted
	}) => {
		return {
			id,
			itemName,
			itemDescription,
			type,
			charge: charge.toString(),
			adjusted
		}
	}) || []
}

const mapInvoiceItemToPriceSummary = (invoiceItems: MappedInvoiceItem[]): PriceSummary[] => {
	return invoiceItems?.map(({
		itemName,
		charge,
		itemDescription
	}) => {
		return {
			title: itemName,
			content: itemDescription,
			price: charge
		}
	}) || []
}

const mapInvoicePayments = (payments: InvoicePayment[]): MappedInvoicePayment[] => {
	return payments?.map(({
		invoicePaymentId,
		amountCharged,
		chargeStatus,
		success,
		refunded,
		refundedPartialAmount,
		refundedDate,
		ccLastFour = 0,
		bankLastFour = 0,
		offlinePaymentNumber = '',
		createdTime,
		lastModified,
		adjustmentReason
	}) => {
		return {
			invoicePaymentId,
			amountCharged,
			chargeStatus,
			success,
			refunded,
			refundedPartialAmount,
			refundedDate: refundedDate ? new Date(refundedDate) : undefined,
			ccLastFour,
			bankLastFour,
			offlinePaymentNumber,
			createdTime: new Date(createdTime),
			lastModified,
			paymentType: getPaymentType(ccLastFour, bankLastFour),
			adjustmentReason
		}
	}) || []
}

const getPaymentType = (ccLastFour: number, bankLastFour: number): PaymentType => {
	if (ccLastFour) {
		return CREDIT_CARD
	} else if (bankLastFour) {
		return BANK_ACCOUNT
	}
	return CHECK_OR_OFFLINE_PAYMENT
}

const mapInvoicePaymentToPriceSummary = (payments: MappedInvoicePayment[]): PriceSummary[] => {
	return payments?.map(({
		amountCharged,
		refunded,
		refundedPartialAmount,
		refundedDate,
		ccLastFour,
		bankLastFour,
		offlinePaymentNumber,
		createdTime,
		success,
		adjustmentReason
	}) => {
		const getContent = () => {
			if (ccLastFour) {
				return `Card ending in ${ccLastFour}`
			} else if (bankLastFour) {
				return `Account ending in ${bankLastFour}`
			} else if (adjustmentReason) {
				return `Adjustment Reason: ${adjustmentReason}`
			} else if (offlinePaymentNumber) {
				return `Offline Payment #${offlinePaymentNumber}`
			} else {
				return ''
			}
		}
		const getStatus = () => {
			if (refunded && refundedPartialAmount) {
				return `Partially Refunded (Original Charge: $${amountCharged})`
			} else if (refunded) {
				return 'Refunded'
			} else if (!success) {
				return 'Failed'
			}
			return ''
		}
		const getPrice = () => {
			const price = amountCharged || refundedPartialAmount || 0

			if (refunded && refundedPartialAmount) {
				return (refundedPartialAmount * -1)?.toString() || ''
			}
			return (refunded ? price * -1 : price)?.toString() || ''
		}
		return {
			title: formatDate(refundedDate) || formatDate(createdTime),
			content: getContent(),
			status: getStatus(),
			price: getPrice(),
			success
		}
	}) || []
}

const mapInvoiceCustomerInfo = (invoiceCustomerInfo: InvoiceCustomerInfo): MappedInvoiceCustomerInfo => {
	const {
		service_address: serviceAddress,
		customerName,
		customerPhone,
		customerEmail
	} = invoiceCustomerInfo
	return {
		serviceAddress,
		customerName,
		customerPhone,
		customerEmail
	}
}

const mapInvoiceApiResponse = (invoice: InvoiceApiResponse): MappedInvoice => {
	const {
		invoiceId,
		taxTotal,
		subtotal,
		totalAmount,
		invoiceStartDate: startDate,
		invoiceEndDate: endDate,
		dueDate,
		createdTime,
		invoiceItems,
		invoiceCustomerInfo,
		payments,
		amount_due: amountDueSnake,
		amountDue,
		allowsOfflinePayment,
		installTotal,
		installRemaining,
		installationDiscountTotal,
		taxPercent,
		accountId,
		autopayFailed
	} = invoice
	return {
		id: invoiceId?.toString(),
		accountId: accountId?.toString(),
		taxTotal,
		subtotal,
		totalAmount,
		startDate: new Date(startDate),
		endDate: new Date(endDate),
		dueDate: new Date(dueDate),
		createdTime: new Date(createdTime),
		items: mapInvoiceItems(invoiceItems),
		customerInfo: mapInvoiceCustomerInfo(invoiceCustomerInfo),
		payments: mapInvoicePayments(payments),
		amountDue: amountDueSnake || amountDue || 0,
		installTotal,
		installRemaining,
		installationDiscountTotal,
		allowsOfflinePayment,
		taxPercent,
		autopayFailed
	}
}

const mapInvoices = (invoices: PaymentMethodsApiResponse): PaymentMethod[] => {
	const mappedCreditCards: PaymentMethod[] = invoices?.creditCards?.map(({
		creditCardId,
		last4,
		expiryMonth,
		expiryYear
	}) => {
		return {
			id: creditCardId?.toString() || '',
			type: CREDIT_CARD,
			last4,
			expiration: `${expiryMonth}/${expiryYear}`
		}
	}) || []

	const mappedBankAccounts: PaymentMethod[] = invoices?.bankAccounts?.map(({
		bankAccountId,
		bankLast4: last4
	}) => {
		return {
			id: bankAccountId?.toString() || '',
			type: BANK_ACCOUNT,
			last4
		}
	}) || []
	return [ ...mappedCreditCards, ...mappedBankAccounts ]
}

export { mapInvoiceApiResponse, mapInvoiceItemToPriceSummary, mapInvoicePaymentToPriceSummary, mapInvoices }
