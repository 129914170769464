<template>
	<div class="panel">
		<div class="flex items-center mb-4 space-x-2">
			<RectangleStackIcon class="h-6 text-mx-gray-500 dark:text-mx-gray-400" />
			<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
				Current Invoice
			</span>
		</div>
		<div class="grid grid-cols-2 gap-y-2 md:hidden">
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					Date
				</span>
				<span class="text-lg font-semibold">
					{{ formatDate(invoice?.createdTime, MSDY) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					ID
				</span>
				<span class="text-lg font-semibold">
					{{ invoice?.invoiceId }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					Amount
				</span>
				<span class="text-lg font-semibold">
					{{ toCurrencyString(invoice?.totalAmount) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					Due Date
				</span>
				<span class="text-lg font-semibold">
					{{ formatDate(invoice?.dueDate, MSDY) }}
				</span>
			</div>
			<div class="flex flex-col">
				<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
					Invoice
				</span>
				<NuxtLink
					:to="`/billing/invoices/${invoice?.invoiceId}`"
				>
					<span class="text-mx-orange uppercase font-semibold link-text hover:bg-mx-gray-100 lg:px-3 py-1.5 rounded-lg dark:hover:bg-mx-green-800">
						View
					</span>
				</NuxtLink>
			</div>
		</div>
		<div class="hidden -mx-6 lg:-mx-8 md:block">
			<table class="w-full text-left">
				<thead>
					<tr class="text-sm uppercase border-y-2 bg-mx-gray-100 dark:border-y-mx-green-900 dark:bg-mx-green-800">
						<th class="py-4 pl-6 pr-3 font-medium lg:pl-8">
							Date
						</th>
						<th class="pr-3 font-medium">
							ID
						</th>
						<th class="pr-3 font-medium">
							Amount
						</th>
						<th class="pr-3 font-medium">
							Due Date
						</th>
						<th class="pr-3 font-medium">
							Invoice
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="pt-4 pl-6 pr-3 lg:pl-8">
							{{ formatDate(invoice?.createdTime, MSDY) }}
						</td>
						<td class="pt-4 pr-3">
							{{ invoice?.invoiceId }}
						</td>
						<td class="pt-4 pr-3">
							{{ toCurrencyString(invoice?.totalAmount) }}
						</td>
						<td class="pt-4 pr-3">
							{{ formatDate(invoice?.dueDate, MSDY) }}
						</td>
						<td class="pt-4 pr-3">
							<NuxtLink
								:to="`/billing/invoices/${invoice?.invoiceId}`"
							>
								<span class="text-mx-orange uppercase font-semibold link-text lg:-ml-3 hover:bg-mx-gray-100 lg:px-3 py-1.5 rounded-lg dark:hover:bg-mx-green-800">
									View
								</span>
							</NuxtLink>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { MappedInvoice } from '@/components/account/invoices/types'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { formatDate, MSDY } from '@/utils/formatDate'
import { toCurrencyString } from '@/utils/formatNumbers'

const props = defineProps({
	invoice: {
		type: Object as PropType<MappedInvoice | null>,
		required: true
	}
})

const { invoice } = toRefs(props)

</script>
