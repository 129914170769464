<template>
	<Listbox
		v-model="selected"
		as="div"
	>
		<ListboxLabel
			v-if="label"
			:class="[
				staticLabel ? 'sr-only' : labelClass,
				required ? 'after:content-[\'*\'] after:font-bold after:text-red-500 after:ml-1' : ''
			]"
		>
			{{ label }}
		</ListboxLabel>
		<div class="relative border-none rounded-lg">
			<ListboxButton as="template">
				<button class="relative w-full pl-3 pr-10 text-left text-black bg-white border rounded-md shadow-sm cursor-pointer dark:shadow-inner dark:border-none h-11 dark:bg-mx-green-800 dark:text-mx-gray-300 focus:outline-none focus:ring-2 focus:ring-mx-orange sm:text-sm sm:leading-6">
					<span class="block truncate">
						{{ staticLabel ? label : selected?.name }}
					</span>
					<span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
						<ChevronUpDownIcon
							class="w-5 h-5 text-mx-gray-400"
							aria-hidden="true"
						/>
					</span>
				</button>
			</ListboxButton>

			<Transition
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<ListboxOptions :class="['absolute right-0 z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-mx-green-800 max-h-60 ring-1 ring-mx-orange ring-opacity-5 focus:outline-none sm:text-sm', optionWidth]">
					<ListboxOption
						v-for="option in options"
						:key="option.id"
						v-slot="{ active, selected: newSelected }"
						as="template"
						:value="option"
						:disabled="option.disabled"
					>
						<li
							:class="[
								active ? 'bg-mx-gray-100 dark:bg-mx-green-600 text-black dark:text-white' : 'text-mx-gray-500 dark:text-mx-gray-300', 'relative select-none py-2 pl-3 pr-9',
								option.disabled? 'text-mx-gray-300/90 cursor-not-allowed dark:text-mx-gray-500' : 'cursor-pointer'
							]"
						>
							<span
								:class="[
									newSelected ? 'font-semibold' : 'font-normal', 'block truncate mr-2'
								]"
							>
								{{ option.name }}
							</span>

							<span
								v-if="newSelected"
								:class="[
									active ? 'text-white' : 'text-mx-orange', 'absolute inset-y-0 right-0 flex items-center pr-4'
								]"
							>
								<CheckIcon
									class="w-5 h-5"
									aria-hidden="true"
								/>
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</Transition>
		</div>
		<div
			v-if="showInputInstruction"
			class="py-1"
		>
			<slot name="instruction">
				<div
					class="pl-1 text-xs italic text-mx-gray-500 dark:text-mx-gray-400"
					v-html="inputInstruction"
				/>
			</slot>
		</div>
	</Listbox>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import type { SelectOption } from '@/components/form/types'

const props = defineProps({
	label: {
		type: String,
		required: true
	},
	options: {
		type: Array as PropType<SelectOption[]>,
		required: true
	},
	selectedIndex: {
		type: Number,
		default: 0
	},
	labelClass: {
		type: String,
		default: 'text-sm font-semibold uppercase text-mx-gray-500 dark:text-mx-gray-300 whitespace-nowrap min-h-5'
	},
	showInputInstruction: {
		type: Boolean,
		default: false
	},
	inputInstruction: {
		type: String,
		default: ''
	},
	staticLabel: {
		type: Boolean,
		default: false
	},
	optionWidth: {
		type: String,
		default: 'w-full'
	},
	required: {
		type: Boolean,
		default: false
	}
})

const { label, options, selectedIndex } = toRefs(props)
const selected = ref(options.value[selectedIndex.value])

watch(selectedIndex, (value) => {
	selected.value = options.value[value]
})

const emit = defineEmits([ 'update:modelValue' ])

watch(selected, (value) => {
	emit('update:modelValue', value.id)
})
</script>

<style scoped>
/* WebKit Browsers */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #6A6A6A;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ADADAD;
}

/* Remove scrollbar buttons */
::-webkit-scrollbar-button {
	display: none;
}

/* Firefox Browsers */
* {
	scrollbar-width: thin;
	scrollbar-color: #6A6A6A transparent;
}
</style>
