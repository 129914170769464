const toTwoDecimalString = (value: number) => {
	return value.toFixed(2)
}

const toCurrencyString = (price: number | undefined | null): string => {
	if (typeof price === 'undefined' || price === null) {
		return ''
	}
	const USDollar = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	})
	return USDollar.format(price)
}

export { toTwoDecimalString, toCurrencyString }
