<template>
	<div class="w-full panel-wrapper">
		<ClientOnly>
			<LoadingSpinner
				v-if="loading"
				class="w-full h-56 p-8 dark:text-mx-gray-400"
			/>
			<div
				v-else
				class="flex flex-col w-full space-y-6 lg:grid lg:grid-cols-4 lg:gap-x-6 dark:text-white"
			>
				<div
					v-if="hasMultipleAccounts"
					class="flex justify-between space-x-4 lg:col-span-4"
				>
					<div class="flex flex-wrap items-center">
						<span class="mr-4 text-2xl font-semibold capitalize">
							{{ fullName }}
						</span>
						<span class="text-sm text-mx-orange">
							{{ `#${accountId} ${formatAddress(selectedAccount?.address)}` }}
						</span>
					</div>
					<FormSelect
						:options="accountOptions"
						label="Switch Account"
						static-label
						option-width="max-w-80"
						:selected-index="selectedIndex"
						@update:model-value="setSelectedAccount"
					/>
				</div>
				<div
					v-if="hasNextPayment"
					class="col-span-4 panel lg:h-32 lg:flex lg:justify-between lg:items-center lg:justify-items-center"
				>
					<div class="mb-4 lg:mb-0">
						<!-- #TODO: i18n for this page -->
						<div class="text-2xl font-medium">
							Next {{ hasAutoPay ? 'auto payment' : 'payment' }} is {{ hasAutoPay ? 'scheduled for' : 'due' }} {{ nextPaymentDueDate }} for {{ nextBillingCycleAmount }}
						</div>
						<div
							v-if="hasAutoPay"
							class="mt-2 dark:text-mx-gray-400 lg:mt-0"
						>
							From <span>
								{{ autopayPaymentMethod?.type === BANK_ACCOUNT ? 'Bank Account' : 'Credit Card' }}
							</span> ending in {{ autoPayLastFourDigits }}
						</div>
					</div>

					<div>
						<div
							v-if="!hasAutoPay"
							class="flex w-full text-center"
						>
							<NuxtLink
								:to="`/billing/invoices/${nextPaymentInvoiceId}`"
								class="flex items-center justify-center w-full rounded-lg lg:w-56 h-9 bg-mx-orange hover:bg-mx-orange-muted hover:border-mx-orange-muted dark:hover:border-transparent dark:hover:bg-mx-orange/80"
							>
								Pay Now
							</NuxtLink>
						</div>
						<div
							v-else
							class="flex"
						>
							<NuxtLink
								to="/billing/payment-methods"
								class="text-mx-orange link-text hover:bg-mx-gray-100 lg:px-3 py-1.5 rounded-lg dark:hover:bg-mx-green-800"
							>
								Cancel or Change Payment Method
							</NuxtLink>
						</div>
					</div>
				</div>
				<div class="flex flex-col items-center justify-between lg:col-span-2 panel lg:flex-row lg:mt-6">
					<div class="flex flex-col items-center text-center lg:flex-row lg:text-left lg:space-x-6">
						<ExclamationTriangleIcon
							v-if="totalOverdueAmount"
							class="h-10 text-red-500"
						/>
						<div class="flex flex-col">
							<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
								Amount Due Today
							</span>
							<span class="text-xl font-semibold">
								{{ toCurrencyString(totalOverdueAmount) }}
							</span>
						</div>
					</div>
					<FormButton
						v-if="totalOverdueAmount"
						class="mt-2"
						button-width="w-full lg:w-56"
						button-text="Make a Payment"
						@click="handlePaymentClick"
					/>
				</div>
				<div class="flex items-center justify-between panel">
					<div class="flex flex-col">
						<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
							Next Billing Amount
						</span>
						<span class="text-xl font-semibold">
							{{ nextBillingCycleAmount }}
						</span>
					</div>
					<IconDollarSign class="h-6 text-mx-gray-500 dark:text-mx-gray-400" />
				</div>
				<div class="flex items-center justify-between panel">
					<div class="flex flex-col">
						<span class="text-sm uppercase text-mx-gray-500 dark:text-mx-gray-400">
							Next Billing Date
						</span>
						<span class="text-xl font-semibold">
							{{ msdyDate }}
						</span>
					</div>
					<CalendarIcon class="h-6 text-mx-gray-500 dark:text-mx-gray-400" />
				</div>
				<AccountDashboardCurrentInvoice
					v-if="hasNextPayment"
					class="col-span-4 lg:col-span-2"
					:invoice="nextPaymentDue"
				/>
				<AccountDashboardPaymentMethods
					class="col-span-4 lg:col-span-2"
					:payment-methods="paymentMethods"
				/>
			</div>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { useInvoices } from '@/composables/useInvoices'
import { ExclamationTriangleIcon, CalendarIcon } from '@heroicons/vue/24/outline'
import { toCurrencyString } from '@/utils/formatNumbers'
import { formatDate, MSDY } from '@/utils/formatDate'
import { usePaymentMethods } from '@/composables/usePaymentMethods'
import { BANK_ACCOUNT } from '@/constants/payment'
import { useAuth } from '@/composables/useAuth'
import type { MappedAccount } from '@/types/auth'

const { getInvoices, nextPaymentDueDate, hasNextPayment, nextPaymentDueAmount, totalOverdueAmount, getPastDue, nextPaymentInvoiceId, loading, nextPaymentDue } = useInvoices()
const { getPaymentMethods, hasAutoPay, autopayPaymentMethod, autoPayLastFourDigits, paymentMethods } = usePaymentMethods()
const { accountId, hasMultipleAccounts, selectedAccount, fullName, accounts, setSelectedAccount, formatAddress } = useAuth()

watch(accountId, () => {
	if (!accountId.value) {
		return
	}
	getInvoices({ limit: 24 })
	getPastDue({ limit: 24 })
	getPaymentMethods()
}, {
	immediate: true
})

const handlePaymentClick = async () => {
	await navigateTo('/billing/invoices')
}

const nextBillingCycleAmount = computed(() => toCurrencyString(nextPaymentDueAmount.value))
const msdyDate = computed(() => formatDate(nextPaymentDueDate?.value, MSDY) || 'N/A')

const accountOptions = computed(() => {
	return accounts?.value.map((account) => {
		return {
			id: account.accountId,
			name: formatAddress(account.address)
		}
	})
})

const selectedIndex = computed(() => {
	return accounts?.value.findIndex((account: MappedAccount) => account.accountId === selectedAccount?.value?.accountId)
})

</script>
